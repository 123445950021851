<app-windowlist [model]="this">
    <div class="row">
        <div class="col-lg-2 pr-lg-0" *ngIf="filter_box">
            <app-filtercontainer [title]="'Filtra per date, fasi, tipologie, ...'" >
                <app-filterdate [date_type]="'100'" [future]="true" [class]="'card mt-2 p-1'" [filter_date]="filter_date" (onSelect)="updateList()" [period]="false" [start]="false" [title]="'In scadenza al'"></app-filterdate>
                <ul class="card small-box p-1 list-group mt-2">
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_phase.value==''" (click)="filter_phase.value='';updateList()">Tutte le fasi</li>
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_phase.value=='1'" (click)="filter_phase.value='1';updateList()">Attivo</li>
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_phase.value=='2'" (click)="filter_phase.value='2';updateList()">Concluso</li>
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_phase.value=='3'" (click)="filter_phase.value='3';updateList()">Annullato</li>
                </ul>
                <ul *ngIf="type_contract && type_contract.length>1" class="card small-box p-1 list-group mt-2 f{{type_contract.length}}">
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_id_type.value==''" (click)="filter_id_type.value='';updateList()">Tutte le tipologie</li>
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_id_type.value==t.id" *ngFor="let t of type_contract" (click)="filter_id_type.value=t.id;updateList()"> {{t.name}}</li>
                </ul>
                <ul class="card small-box p-1 list-group my-2">
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_no_installments.enabled==false" (click)="filter_no_installments.enabled=false;updateList()">Tutti contratti</li>
                    <li class="list-group-item text-truncate pointer" [class.active]="filter_no_installments.enabled==true" (click)="filter_no_installments.enabled=true;updateList()">Solo i contratti senza piano rate</li>
                </ul>
            </app-filtercontainer>
        </div>
        <div [class.col-lg-10]="filter_box" [class.col-lg-12]="!filter_box" >
            <div class="card card-outline mt-2">
                <div class="p-0">
                    <div class="row">
                        <div class="col-6 col-lg-9">
                            <div class="input-group input-group-sm m-1" >
                                <input type="text" name="table_search" class="form-control float-right" placeholder="cerca per ubicazione o descrizione contratto..." [(ngModel)]="filter_search.value" (keyup.enter)="getItems()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default text-primary"  title="Avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''" title="Annulla ricerca" (click)="filter_search.value='';getItems();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3" *ngIf="mode!='modal'">
                            <div class="pr-1">
                                <app-windowlistbuttons 
                                [model]="this"  
                                [table]="'contracts'" 
                                [funs]="[]"
                                ></app-windowlistbuttons>
                            </div>
                            <!-- <button  title="Cestina" *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1 mr-1"><i class="far fa-trash-alt"></i></button>
                            <button  title="Elimina" *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 mt-1"><i class="fas fa-trash"></i></button>
                            <button  title="Ripristina" *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                            <button  title="Esporta" (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button>
                            <button  title="Aggiungi" (click)="openDetail()" class="btn btn-success btn-xs float-right ml-1 mt-1"><i class="fas fa-plus"></i></button> -->
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>    
                    </div>

                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-sm table-striped table-head-fixed table-hover text-sm m-0" *ngIf="list.length>0">
                            <thead class="bg-light">
                                <tr>
                                    <th>
                                        <div #checkboxselectall class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" title="Seleziona tutti"
                                            (change)="onChangeAll($event.target.checked)" />
                                            &ensp;ID
                                        </div>
                                    </th>
                                    <!-- <th class="d-none d-sm-table-cell text-center">
                                        ID
                                    </th> -->
                                    <th>
                                        Descrizione
                                        <button class="btn btn-tool" (click)="switchOrdering('c.id_type')">
                                            <i class="fas fa-sort {{classOrdering('c.id_type')}}"  ></i>
                                        </button>
                                    </th>
                                    <th class="" *ngIf="id_shop==0">Dati contratto</th>
                                    <th class="d-none d-sm-table-cell text-center">Numero rate</th>
                                    <th class="d-none d-sm-table-cell text-right">Preventivato</th>
                                    <th class="d-none d-sm-table-cell text-right">Incassato</th>
                                    <th class="d-none d-sm-table-cell text-right">Da incassare</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <thead>
                                <tr *ngIf="total">
                                    <td colspan="4"><b>TOTALE</b></td>
                                    <td class="text-right text-secondary">
                                        <b>{{total.total_deadlines | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="text-right text-success">
                                        <b>{{total.total_activities | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="text-right text-danger">
                                        <b>{{total.total_topaid | currency:'EUR':true }}</b>
                                    </td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- elemento ripetuto -->
                                <tr *ngFor="let record of list">
                                    <td>
                                        <div class="icheck-primary d-inline mr-3">
                                            <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                            (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                            />
                                            &ensp;{{record.id}}
                                        </div>
                                    </td>
                                    <!-- <td class="d-none d-sm-table-cell text-center">
                                        {{record.id}}
                                    </td> -->
                                    <td>
                                        <i title="Attivo" *ngIf="record.phase==1" class="fas fa-clock text-warning help"></i>
                                        <i title="Concluso" *ngIf="record.phase==2" class="fas fa-check text-success help"></i>
                                        <i title="Annullato" *ngIf="record.phase==3" class="fas fa-times text-danger help"></i>
                                        <span class="ml-2 pointer" title="Modifica/Vedi" [class.trashed]="record.status == 2" (click)="openDetail(record)">
                                            <b>{{record.type}}</b><br>
                                            <i>{{record.description}}</i>
                                        </span>                                      
                                    </td>
                                    <td *ngIf="id_shop==0">
                                        <div *ngIf="record.addressname"><i class="fas fa-user text-gray mx-1"></i><b>{{record.addressname}}</b></div>
                                        <div *ngIf="record.shop"><i class="fas fa-store-alt text-gray mr-1"></i><b>{{record.shop}}</b></div>
                                        <i class="fa fa-calendar-alt text-gray mx-1"></i>
                                        <small>{{record.start | date:'dd MMM yyyy'}} - {{record.end | date:'dd MMM yyyy'}}</small>                                        
                                        <div *ngIf="record.phase==1 && testDate(record.end)" class="badge badge-warning ml-1"><i class="fas fa-exclamation-triangle mr-1"></i>Scaduto</div>                                       
                                        <br/>
                                        <div class="badge border" >{{record.gross | currency:"&euro;"}}
                                            <span *ngIf="record.amount_type=='30'">/mese</span>
                                            <span *ngIf="record.amount_type=='360'">/anno</span>
                                        </div>
                                        <br>
                                        <small class="d-sm-none"><small>
                                            N. RATE:&ensp;<b>{{record.count_installments_activities.count}}</b>/{{record.count_installments_deadlines.count}}<br>
                                            PREVENTIVATO:&ensp;<b class="text-secondary">{{record.count_installments_deadlines.total | currency:" &euro;"}}</b><br>
                                            INCASSATO:&ensp;<b class="text-success">{{record.count_installments_activities.total | currency:" &euro;"}}</b><br>
                                            DA INCASSARE:&ensp;<b [class.text-danger]="record.count_installments_deadlines.total-record.count_installments_activities.total>0">{{record.count_installments_deadlines.total-record.count_installments_activities.total | currency:" &euro;"}}</b>
                                        </small></small>                                    
                                    </td>
                                    <td class="d-none d-sm-table-cell text-center">
                                        <span *ngIf="record.count_installments_deadlines.count">
                                            <span *ngIf="!record.count_installments_activities.count">0</span>
                                            {{record.count_installments_activities.count}}                                            
                                            /{{record.count_installments_deadlines.count}}                                        
                                        </span>
                                    </td>      
                                    <td class="d-none d-sm-table-cell text-right text-secondary">
                                        {{record.count_installments_deadlines.total | currency:" &euro;"}}
                                    </td>
                                    <td class="d-none d-sm-table-cell text-right text-success">{{record.count_installments_activities.total | currency:" &euro;"}}

                                    </td>
                                    <td class="d-none d-sm-table-cell text-right" [class.text-danger]="record.count_installments_deadlines.total-record.count_installments_activities.total>0">
                                        {{record.count_installments_deadlines.total-record.count_installments_activities.total | currency:" &euro;"}}
                                    </td>
                                    <td>
                                        <button type="button" *ngIf="mode!='modal'" (click)="openDetail(record)" title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1 mt-1">
                                            <i class="fas fa-pencil-alt"></i></button>
                                        <button type="button" *ngIf="mode=='modal'" class="btn btn-secondary btn-xs float-right m-1" (click)="selectRecord(record)">
                                            <span class="d-none d-sm-inline-block">Seleziona&ensp;</span><i class="fas fa-chevron-right"></i></button>
                                        <button type="button" *ngIf="mode!='modal'"  (click)="exportInRow(record)" title="Stampa" class="btn btn-warning btn-xs float-right ml-1 mt-1">
                                            <i class="fas fa-print"></i></button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="notify(record)" title="Notifica" class="btn btn-primary btn-xs float-right ml-1 mt-1">
                                            <i class="far fa-paper-plane"></i></button>
                                        <button type="button" *ngIf="mode!='modal'" (click)="newform(record)" title="Modulo" class="btn btn-outline-secondary btn-xs float-right ml-1 mt-1">
                                            <i class="fab fa-wpforms"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr *ngIf="total">
                                    <td colspan="4"><b>TOTALE</b></td>
                                    <td class="text-right text-secondary">
                                        <b>{{total.total_deadlines | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="text-right text-success">
                                        <b>{{total.total_activities | currency:'EUR':true }}</b>
                                    </td>
                                    <td class="text-right text-danger">
                                        <b>{{total.total_topaid | currency:'EUR':true }}</b>
                                    </td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>