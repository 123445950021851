import { Component, Input, OnInit } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { File, ToastMode } from 'projects/core/src/include/structures';
import { FilesService } from 'projects/core/src/lib/files.service';
import { UploadService } from 'projects/core/src/lib/upload.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit {

  get config():any{
    return Globals.config;
  }

  @Input()
  files:File[]=[];

  @Input()
  directory:string="";

  fileToUpload = null;

  constructor(
    private uploadService:UploadService,
    private filesService:FilesService
  ) { }

  ngOnInit(): void {
  }


  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,this.directory,(result)=>{
      if(result){
        let df:File=new File();
        df.file=result;
        df.name=this.fileToUpload['name'];

        if(this.files==null){ //inizializza l'array
          this.files=[];
        }
        this.files.push(df);

        //salva nel db        

      }else{
        Globals.message.showToaster("Errore nel caricamento del file",ToastMode.DANGER);
      }
      Globals.setLoading(false);     
    },false);
  }
  

  downloadFile(f:File){
    window.open(Globals.config.serverUrl+"/"+f.file);
  }


  removeFile(f:File){
    Globals.modal.showConfirm("","Confermi di voler eliminare il file?",()=>{  
      for(let i=0;i<this.files.length;i++){
        if(this.files[i]==f){
          this.files.splice(i,1);
        }
      }
    },"Sì. Elimina","No. Annulla","danger");
  }


  getExtension(f:File){
    if(f)
      return f.file.split('.').pop().toLowerCase();;
    return "";
  }

}
